import Modal from "../Modal";
import Header2 from "../Header2";
import PrimaryButtonLink from "../PrimaryButtonLink";
import SecondaryButtonLink from "../SecondaryButtonLink";

export default function ModalGettingStarted({
  isOpen,
  setOpen,
}) {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      maxWidth="lg"
    >
      <div className="text-center">
          <Header2 className="text-lg font-medium !text-astronaut-blue">
              Woohoo! Excited to get started!
          </Header2>

          <p className="mt-6">
              Before we dive in, please create an account or log
              in.
          </p>

          <div className="mt-2 justify-center">
              <PrimaryButtonLink href={route("register")}>
                  Create Account
              </PrimaryButtonLink>

              <SecondaryButtonLink
                  className="ml-4 m-4"
                  href={route("login")}
              >
                  Log In
              </SecondaryButtonLink>
          </div>
      </div>
  </Modal>
  );
}
